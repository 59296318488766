import store from "@/state/store";
import axios from "axios";
var state = store.state;
export default {
  install(Vue) {
    var self = Vue.config.globalProperties;
    Vue.config.globalProperties.http = {
      post(url, data, options, hasAuth) {
        return new Promise((resolve) => {
          if (!hasAuth || window.loginDone) callServer();
          else {
            var loginInterval = setInterval(() => {
              console.log(window.loginDone);
              if (window.loginDone) {
                clearInterval(loginInterval);
                callServer();
              }
            }, 100);
          }

          function callServer() {
            if (!options) options = {};
            if (!options.headers) options.headers = {};
            // if (state.token) {
            //   options.headers["x-auth"] = state.token;
            //// data.user_id = state.currentUser.id;
            // }
            //          data.user_id = state.currentUser.id;

            console.log(options);
            data.lang = Vue.config.globalProperties.$i18n.locale;
            axios.post(state.baseUrl + url, data, options).then((resp) => {
              console.log(resp.headers["x-auth"]);
              if (resp.headers["x-auth"]) {
                state.token = resp.headers["x-auth"];
                console.log(data.pass);
                resp.data.data.pass = self.$e.encrypt(data.pass);
                state.currentUser = resp.data.data;
                localStorage.currentUser = JSON.stringify(resp.data.data);
              }
              console.log({ ShityData: resp.data });
              if (resp.data.status) {
                resolve(resp.data);
              } else {
                resolve(resp.data);
                Vue.config.globalProperties.popup.alert({
                  title: "popups.error",
                  msg: resp.data.msg,
                });
              }
            });
          }
        });
      },
      do(url, data, options) {
        return new Promise((resolve) => {
          if (!options) options = {};
          if (!options.headers) options.headers = {};
          // if (state.token) {
          //   options.headers["x-auth"] = state.token;
          //data.user_id = state.currentUser.id;
          // }
          //// data.user_id = state.currentUser.id;

          console.log(options);
          data.lang = Vue.config.globalProperties.$i18n.locale;
          var formData = new FormData();
          for (var key in data) {
            formData.append(key, data[key]);
          }
          axios.post(state.baseUrl + url, formData, options).then((resp) => {
            console.log(resp.headers["x-auth"]);
            if (resp.headers["x-auth"]) {
              state.token = resp.headers["x-auth"];
              console.log(data.pass);
              resp.data.data.pass = self.$e.encrypt(data.pass);
              state.currentUser = resp.data.data;
              localStorage.currentUser = JSON.stringify(resp.data.data);
              console.log(state.token);
            }
            if (resp.data.status) {
                resolve(resp.data);
              }
              else {
                  self.popup.alert({
                    title: "popups.error",
                    msg: resp.data.msg,
                  });
                
              }
          });
        });
      },
      put(url, id, data, options) {
        return new Promise((resolve) => {
          if (!options) options = {};
          if (!options.headers) options.headers = {};
          // if (state.token) {
          //   options.headers["x-auth"] = state.token;
          //data.user_id = state.currentUser.id;
          // }

          //data.user_id = state.currentUser.id;

          console.log(options);
          // data.lang = Vue.config.globalProperties.$i18n.locale
          axios
            .put(state.baseUrl + url + "/" + id, data, options)
            .then((resp) => {
              console.log(resp.headers["x-auth"]);
              if (resp.headers["x-auth"]) {
                state.token = resp.headers["x-auth"];
                console.log(data.pass);
                resp.data.data.pass = self.$e.encrypt(data.pass);
                state.currentUser = resp.data.data;
                localStorage.currentUser = JSON.stringify(resp.data.data);
                console.log(state.token);
              }
               if (resp.data.status) {
                resolve(resp.data);
              }
              else {
                  self.popup.alert({
                    title: "popups.error",
                    msg: resp.data.msg,
                  });
                
              }
            });
        });
      },
      get(url, data, options, hasAuth) {
        return new Promise((resolve) => {
          if (!hasAuth || window.loginDone) callServer();
          else {
            console.log("here 100");

            var loginInterval = setInterval(() => {
              console.log("here 200", window.loginDone);
              if (window.loginDone) {
                clearInterval(loginInterval);
                callServer();
              }
            }, 100);
          }
          function callServer() {
            if (!data) data = {};
            if (!options) options = {};
            if (!options.headers) options.headers = {};
            if (state.token) {
              options.headers["x-auth"] = state.token;
              //data.user_id = state.currentUser.id;
            }
            console.log("step 200 : ", store.state.currentUser);

            console.log(options);
            data.lang = Vue.config.globalProperties.$i18n.locale;
            axios
              .get(
                state.baseUrl +
                  url +
                  "?" +
                  new URLSearchParams(data).toString(),
                data,
                options
              )
              .then((resp) => {
                // console.log("the status is",resp.data.status)
                console.log(resp.headers["x-auth"]);
                if (resp.headers["x-auth"]) {
                  state.token = resp.headers["x-auth"];
                  console.log(data.pass);
                  resp.data.data.pass = self.$e.encrypt(data.pass);
                  state.currentUser = resp.data.data;
                  localStorage.currentUser = JSON.stringify(resp.data.data);
                  console.log(state.token);
                }
                if (resp.data.status) {
                resolve(resp.data);
              }
              else {
                  self.popup.alert({
                    title: "popups.error",
                    msg: resp.data.msg,
                  });
                
              }
              });
          }
        });
      },
      delete(url, id, options) {
        return new Promise((resolve) => {
          var data = {};
          if (!options) options = {};
          if (!options.headers) options.headers = {};
          // if (state.token) {
          //   options.headers["x-auth"] = state.token;
          //data.user_id = state.currentUser.id;
          // }
          //// data.user_id = state.currentUser.id;

          console.log(options);
          data.lang = Vue.config.globalProperties.$i18n.locale;
          axios
            .delete(
              state.baseUrl +
                url +
                "/" +
                id +
                "?" +
                new URLSearchParams(data).toString(),
              options
            )
            .then((resp) => {
              console.log(resp.headers["x-auth"]);
              if (resp.headers["x-auth"]) {
                state.token = resp.headers["x-auth"];
                console.log(data.pass);
                resp.data.data.pass = self.$e.encrypt(data.pass);
                state.currentUser = resp.data.data;
                localStorage.currentUser = JSON.stringify(resp.data.data);
                console.log(state.token);
              }
              if (resp.data.status) {
                resolve(resp.data);
              }
              else {
                self.popup.alert({
                  title: "popups.error",
                  msg: resp.data.msg,
                });
              
            }
            });
        });
      },
      download(url, fileName,data, options) {
        return new Promise((resolve) => {
          if(!data)
            data = {};
          if (!options) options = { timeout: 5 * 60 * 1000 };
          if (!options.headers) options.headers = {};
          if (state.token) {
            options.headers["x-auth"] = state.token;
            data.user_id = state.currentUser.id;
          }
          console.log(options);
          data.lang = Vue.config.globalProperties.$i18n.locale;

          // progress bar
          function isIE() {
            var myNav = navigator.userAgent.toLowerCase();
            return myNav.indexOf("msie") != -1
                ? parseInt(myNav.split("msie")[1])
                : false;
          }

          axios({
            method: "GET",
            url:
                state.baseUrl + url + "?" + new URLSearchParams(data).toString(),
            data,
            responseType: "arraybuffer",

          })
              .then((data) => {
                var D = document;
                var ieVersion = isIE();
                if (ieVersion && ieVersion < 10) {
                  var frame = D.createElement("iframe");
                  document.body.appendChild(frame);

                  frame.contentWindow.document.open("text/html", "replace");
                  frame.contentWindow.document.write("sep=,\r\n" + data.data);
                  frame.contentWindow.document.close();
                  frame.contentWindow.focus();
                  console.log("hi 1");
                  if (
                      frame.contentWindow.document.execCommand(
                          "SaveAs",
                          true,
                          fileName
                      )
                  ) {
                    console.log("Bill is saved");
                  } else {
                    Vue.config.globalProperties.popup.alert({
                      title:"popups.error",
                      msg:"File cannot be saved. Your browser does not support download."
                    });

                  }

                  document.body.removeChild(frame);
                  return true;
                }

                var file = new Blob([data.data]);
                if (typeof window.navigator.msSaveBlob !== "undefined") {
                  window.navigator.msSaveBlob(file, fileName);
                  console.log("hi msSaveBlob");
                } else {
                  //for chrome in IOS
                  if (navigator.userAgent.match("CriOS")) {
                    file = new Blob([data]);
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                  } else {
                    fileURL = URL.createObjectURL(file);
                    var a = document.createElement("a");
                    if (typeof a.download === "undefined") {
                      window.location = fileURL;
                      console.log("hi win 1");
                    } else {
                      a.href = fileURL;
                      a.target = "_blank";
                      a.download = fileName;
                      document.body.appendChild(a);
                      a.click();
                      console.log("hi win 2");
                    }
                  }
                }
                resolve(true);
              })
              .catch((e) => {
                // $.LoadingOverlay("hide");
                console.log(e);
                var reader = new FileReader();
                reader.onload = function (event) {
                  console.log(event.target.result);
                  var st = JSON.parse(atob(event.target.result.substring(37)));
                  Vue.config.globalProperties.popup.alert({
                    title:"popups.error",
                    msg:st.msg
                  });
                  //resolve(false);
                };
                var array = new Uint8Array(e.data);
                var blob = new Blob([array]);
                reader.readAsDataURL(blob);
              });
        });
      }

    };
  },
};
